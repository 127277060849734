import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import Youtube from '@/components/Youtube';
import {
  STORY_CATEGORY_GOVERNMENT1,
  STORY_CATEGORY_GOVERNMENT2,
  STORY_CATEGORY_GOVERNMENT3,
} from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const tab1: TabData[] = [
  {
    id: 0,
    youtubeId: `sCSl-DtJPOc`,
    title: `RoK UNICEF Annual Consultation`,
  },
  {
    id: 1,
    youtubeId: `5l4UL5GlPSE`,
    title: `한국 정부의 공적개발원조 사업`,
  },
];
const tab2: TabData[] = [
  {
    id: 0,
    youtubeId: `OVuab9vtk4c`,
    title: `캐서린 러셀 총재 방한`,
  },
  {
    id: 1,
    youtubeId: `gkgJS7YfH0k`,
    title: `Reimagine future for Every Child`,
  },
  {
    id: 2,
    youtubeId: `l9HuFrzw08Y`,
    title: `2020년 세계어린이날`,
  },
];

const thumbs1: thumbsData1[] = [
  {
    id: 0,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popSid: 1,
    data: `2021.01.01`,
  },
  {
    id: 1,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popSid: 1,
    data: `2021.01.01`,
  },
  {
    id: 2,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popSid: 1,
    data: `2021.01.01`,
  },
  {
    id: 3,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popSid: 1,
    data: `2021.01.01`,
  },
  {
    id: 4,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popTit: `주요 파트너십`,
    popSid: 1,
    data: `2021.01.01`,
  },
];
const thumbs2: thumbsData2[] = [
  {
    id: 0,
    pcImage: ``,
    mobileImage: ``,
    title: `헨리에타 포어 유니세프 총재, 한국 국회의원과 만나다`,
    popSid: 2,
    data: `2021.01.01`,
  },
  {
    id: 1,
    pcImage: ``,
    mobileImage: ``,
    title: `헨리에타 포어 유니세프 총재, 한국 국회의원과 만나다`,
    popTit: `유니세프의 국회 친구들`,
    popSid: 2,
    data: `2021.01.01`,
  },
  {
    id: 2,
    pcImage: ``,
    mobileImage: ``,
    title: `헨리에타 포어 유니세프 총재, 한국 국회의원과 만나다`,
    popSid: 2,
    data: `2021.01.01`,
  },
  {
    id: 3,
    pcImage: ``,
    mobileImage: ``,
    title: `헨리에타 포어 유니세프 총재, 한국 국회의원과 만나다`,
    popSid: 2,
    data: `2021.01.01`,
  },
  {
    id: 4,
    pcImage: ``,
    mobileImage: ``,
    title: `헨리에타 포어 유니세프 총재, 한국 국회의원과 만나다`,
    popSid: 2,
    data: `2021.01.01`,
  },
];
const thumbs3: thumbsData3[] = [
  {
    id: 0,
    pcImage: ``,
    mobileImage: ``,
    title: `유니세프 사업국 보건국장 스테판 피터슨 아리랑TV 인터뷰`,
    popSid: 3,
    data: `2021.01.01`,
  },
  {
    id: 1,
    pcImage: ``,
    mobileImage: ``,
    title: `유니세프 사업국 보건국장 스테판 피터슨 아리랑TV 인터뷰`,
    popSid: 3,
    data: `2021.01.01`,
  },
  {
    id: 2,
    pcImage: ``,
    mobileImage: ``,
    title: `한국인 최초, 유니세프 집행이사회 사무국 부회장 선출`,
    popSid: 3,
    data: `2021.01.01`,
  },
  {
    id: 3,
    pcImage: ``,
    mobileImage: ``,
    title: `유니세프 사업국 보건국장 스테판 피터슨 아리랑TV 인터뷰`,
    popSid: 3,
    data: `2021.01.01`,
  },
  {
    id: 4,
    pcImage: ``,
    mobileImage: ``,
    title: `유니세프 사업국 보건국장 스테판 피터슨 아리랑TV 인터뷰`,
    popSid: 3,
    data: `2021.01.01`,
  },
];

const Article = styled.article`
  header {
    padding: 96px 0;
    .header-dec {
      line-height: 1.7;
    }
  }

  ${breakpoint(`tablet`)} {
    header {
      .header-dec {
        line-height: 2;
        margin-top: 24px;
      }
    }
  }
`;

const KoreaGovernmentCooperation: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);
  const [story1, setStory1] = useState<CampaignData[]>([]);
  const [story2, setStory2] = useState<CampaignData[]>([]);
  const [story3, setStory3] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A013'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadStories = useCallback(async () => {
    try {
      const {
        data: governmentStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: [
          STORY_CATEGORY_GOVERNMENT1,
          STORY_CATEGORY_GOVERNMENT2,
          STORY_CATEGORY_GOVERNMENT3,
        ],
        outDateDispYn: `Y`,
      });
      setStory1(
        governmentStory
          .filter((story: BoardVo) => story.boardCategoryCode === 'GP01')
          .map((story: BoardVo) => ({
            id: story.boardIndexNumber,
            title: story.subject,
            pcImage: story.imgAttGrpNoTn,
            mobileImage: story.imgAttGrpNoTn,
            popSid: 1,
            contents: story.contents,
            video: story.optData3,
            link: story.optData2,
            date: story.firstRegisterDate,
            imgYn: story.attGrpNoTn,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
      setStory2(
        governmentStory
          .filter((story: BoardVo) => story.boardCategoryCode === 'GP02')
          .map((story: BoardVo) => ({
            id: story.boardIndexNumber,
            title: story.subject,
            pcImage: story.imgAttGrpNoTn,
            mobileImage: story.imgAttGrpNoTn,
            popSid: 2,
            contents: story.contents,
            video: story.optData3,
            link: story.optData2,
            date: story.firstRegisterDate,
            imgYn: story.attGrpNoTn,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
      setStory3(
        governmentStory
          .filter((story: BoardVo) => story.boardCategoryCode === 'GP03')
          .map((story: BoardVo) => ({
            id: story.boardIndexNumber,
            title: story.subject,
            pcImage: story.imgAttGrpNoTn,
            mobileImage: story.imgAttGrpNoTn,
            popSid: 3,
            contents: story.contents,
            video: story.optData3,
            link: story.optData2,
            date: story.firstRegisterDate,
            imgYn: story.attGrpNoTn,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
    loadStories();
  }, [loadStories, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="한국 정부 협력"
      description="for every child, partnerships with government"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">한국 정부 파트너십</PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프는 도움이 필요한 전 세계 어린이를 지원하기 위해 한국
                정부와 협력합니다. 또한 개발도상국의 경제 발전과 사회 복지
                증진을 목표로 하는 공적개발원조(ODA: Official Development
                Assistance)로 어린이 구호 활동을 펼칩니다. 한국 정부가 지원하는
                유니세프 중점사업은 교육·보건·영양·식수와 위생·양성
                평등·이노베이션 등이 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <Youtube tabs={tab1} />

          <Article>
            <header>
              <h3>
                <Tit size="s2">주요 파트너십</Tit>
              </h3>
              <p className="header-dec">
                유니세프는 한국 정부와 다양한 파트너십 협력 관계를 구축해
                2030년까지 지속가능발전목표(SDGs: Sustainable Development
                Goals)를 달성하고자 합니다. 또한 국제사회가 공약한 무상 원조
                지원 사업을 비롯하여 외교부, 통일부, 국회, 서울시청,
                한국국제협력단 등 국제 개발 협력 분야로 사업 발굴 및 이행을
                논의합니다.
              </p>
            </header>
            {story1.length > 0 && (
              <RelativeBoardCard
                newslist={story1}
                isDate={false}
                popInnerTit="주요 파트너십"
                overflowHidden
                isSwiper
                motion
                btn
                pop
              />
            )}
          </Article>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">아동권리 옹호</Tit>
            </h2>
            <p className="header-dec">
              유니세프는 한국 정부와 국회를 대상으로 아동권리 증진 및 옹호
              활동을 합니다. 유니세프는 유엔아동권리협약을 기반으로 2030년까지
              지속가능발전목표(SDGs: Sustainable Development Goals)를 달성하기
              위한 ‘2018-2021 중점 사업 계획’을 발표했습니다. 이에 따라
              유니세프는 한국 정부 및 국회와 함께 전 세계 어린이 권리를 보호하고
              폭 넓은 기회를 제공하도록 노력합니다.
            </p>
          </SectionHeader>

          <Youtube tabs={tab2} bgType="2" />

          <Article>
            <header>
              <h3>
                <Tit size="s2">유니세프의 국회 친구들</Tit>
              </h3>
              <p className="header-dec">
                ‘유니세프의 국회 친구들’(Korean Parliamentarian Friends of
                UNICEF) 의원 모임은 유니세프 창립 60주년을 기념해
                한국아동⋅인구⋅환경의원연맹 소속 국회의원들이 뜻을 모은 후 2007년
                6월 7일 국회에서 창립됐습니다. 현재 리더 도종환 의원과 부리더
                류성걸 의원을 비롯한 40명 이상의 의원이 활동합니다. ‘유니세프의
                국회 친구들’은 정당을 초월해 대한민국 국회의원으로서 전 세계
                어린이를 위한 생존, 보호, 권리 옹호 등 유니세프 활동을 적극
                지원합니다. 또한 국회를 넘어 국내외 다양한 영역에서 아동권리
                증진을 지지하고, 유니세프 공여 지원금 확보에도 적극적 노력을
                기울이고 있습니다.
              </p>
            </header>
            {story2.length > 0 && (
              <RelativeBoardCard
                newslist={story2}
                isDate={false}
                popInnerTit="유니세프의 국회 친구들"
                overflowHidden
                isSwiper
                motion
                btn
                pop
              />
            )}
          </Article>

          <Article>
            <header>
              <h3>
                <Tit size="s2">옹호 활동</Tit>
              </h3>
              <p className="header-dec">
                유니세프는 세계 각국의 정부와 함께 어린이가 누려야 할 생존,
                발달, 보호 등 권리를 옹호하기 위하여 각계각층의 주요 정부
                인사들을 만나 유니세프의 긴급구호 및 지원 프로그램들을 홍보하고
                있습니다. 어린이들이 누려야 할 모든 권리를 보다 효과적으로
                전달하기 위해 다양한 형태로 옹호 활동을 펼치고 있습니다.
              </p>
            </header>
            {story3.length > 0 && (
              <RelativeBoardCard
                newslist={story3}
                isDate={false}
                popInnerTit="옹호 활동"
                overflowHidden
                isSwiper
                motion
                btn
                pop
              />
            )}
          </Article>
        </Container>
      </Section>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A013" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default KoreaGovernmentCooperation;
